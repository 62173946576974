<template>
  <img
    class="p-footerSiteLogo"
    :src="image.url"
    :width="image.width"
    :height="image.height"
    :alt="$t(`${portalId}.おみせのトラスト`)"
  />
</template>

<script>
export const LogoImageMap = {
  shops: {
    ja: {
      url: '/img/common/servicebanner_logo.svg',
      width: 260,
      height: 51,
    },
    en: {
      url: '/img/common/servicebanner_logo_en.svg',
      width: 260,
      height: 51,
    },
  },
  schools: {
    ja: {
      url: '/img/common/servicebanner_logo_schools.svg',
      width: 260,
      height: 51,
    },
    en: {
      url: '/img/common/servicebanner_logo_schools_en.svg',
      width: 260,
      height: 51,
    },
  },
  hospitals: {
    ja: {
      url: '/img/common/servicebanner_logo_hospitals.svg',
      width: 260,
      height: 51,
    },
    en: {
      url: '/img/common/servicebanner_logo_hospitals_en.svg',
      width: 260,
      height: 51,
    },
  },
}

export default {
  name: 'PFooterSiteLogo',
  computed: {
    siteNamePath() {
      return `siteName.${this.portalId}`
    },
    image() {
      const imageOption = LogoImageMap[this.portalId] || LogoImageMap.shops

      return imageOption[this.$i18n.locale] || imageOption.ja
    },
  },
}
</script>
