<template>
  <footer class="p-footer">
    <div class="l-container">
      <dl class="p-footer__about">
        <dt
          class="p-footer__aboutTitle"
          v-t="{
            path: '{siteName}について',
            args: {
              siteName,
            },
          }"
        />
        <dd class="p-footer__aboutContent">
          <div class="content">
            <p
              v-t="
                `${portalId}.感染症対策を実施してる店舗を掲載しています。対策状況を確認することで、利用者が店舗を安心して利用できることを実証します。`
              "
            />
          </div>
        </dd>
      </dl>
      <ul class="p-footer__menu">
        <li class="p-footer__menuItem">
          <app-link
            class="p-footer__menuLink c-link c-link--invert"
            :to="{ name: 'Index', params: { portalId } }"
            v-t="'トップページ'"
          />
        </li>
        <li class="p-footer__menuItem">
          <app-link
            class="p-footer__menuLink c-link c-link--invert"
            :to="{ name: 'Terms', params: { portalId } }"
            v-t="'利用規約'"
          />
        </li>
        <li class="p-footer__menuItem">
          <app-link
            class="p-footer__companyLink c-link c-link--invert"
            href="https://www.hitachi.co.jp/products/it/tplats/index.html"
            target="_blank"
            v-t="'T*Platsとは'"
          />
        </li>
      </ul>
      <dl class="p-footer__service p-footerService">
        <dt class="p-footerService__title" v-t="'関連サービス'" />
        <dd class="p-footerService__content">
          <ul class="p-footerService__list">
            <li
              v-for="portalId in serviceList"
              :key="portalId"
              class="p-footerService__item"
            >
              <PFooterServiceBanner
                class="p-footerService__banner"
                :service-portal-id="portalId"
                target="_blank"
                rel="noopener"
              />
            </li>
          </ul>
        </dd>
      </dl>
      <div class="p-footer__bottom">
        <app-link
          class="p-footer__loginButton"
          :href="managementSiteUrl"
          target="_blank"
          v-t="'管理サイト ログイン'"
        />
        <small class="p-footer__copyright">
          &copy; Hitachi, Ltd. 2022 - 2024. All rights reserved.
        </small>
      </div>
    </div>
  </footer>
</template>

<script>
import { PortalIdList } from '@/libs/define'
import PFooterServiceBanner from '@/components/common/PFooterServiceBanner.vue'

export default {
  name: 'PFooter',
  components: {
    PFooterServiceBanner,
  },
  computed: {
    managementSiteUrl() {
      //  return 'https://management.develop.trusted-shop.hitachi.co.jp/pages/login'
      return process.env.VUE_APP_MANAGEMENT_SITE_URL
    },
    siteName() {
      return this.$t(`${this.portalId}.おみせのトラスト`)
    },
    serviceList() {
      return PortalIdList.filter((portalId) => portalId !== this.portalId)
    },
  },
}
</script>
