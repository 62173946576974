<template>
  <div class="l-app">
    <div ref="header" class="l-header">
      <PHeader />
    </div>
    <div class="l-main" :style="{ paddingTop: `${headerHeight}px` }">
      <router-view
        @setPageTitle="handleSetPageTitle"
        @setPageParents="handleSetPageParents"
      />
    </div>
    <div
      class="l-footer"
      ref="footer"
      :class="{
        'l-footer--showPagetop': pagetopStatus.isShow,
        'l-footer--fitPagetop': pagetopStatus.isFit,
      }"
    >
      <PPagetop @click="toPagetop" />
      <CBreadcrumb v-if="isShowBreadcrumb" :items="breadcrumbItems" />
      <PFooter />
    </div>
  </div>
</template>

<script>
import PHeader from '@/components/common/PHeader.vue'
import CBreadcrumb from '@/components/common/CBreadcrumb.vue'
import PFooter from '@/components/common/PFooter.vue'
import PPagetop from '@/components/common/PPagetop.vue'
import { gsap } from 'gsap'

export default {
  name: 'DefaultLayout',
  components: {
    PHeader,
    CBreadcrumb,
    PFooter,
    PPagetop,
  },
  provide() {
    return {
      scrollTo: this.scrollTo,
    }
  },
  data() {
    return {
      pagetopStatus: {
        isShow: false,
        isFit: false,
      },
      headerHeight: 0,
      pageTitle: '',
      pageParents: [],
      isHeaderMenuOpened: false,
    }
  },
  computed: {
    countPageParents() {
      return this.pageParents ? Object.keys(this.pageParents).length : 0
    },
    breadcrumbItems() {
      if (this.countPageParents != 0) {
        return [
          {
            label: this.$t('トップページ'),
            to: { name: 'Index', params: { portalId: this.portalId } },
          },
          ...this.pageParents,
          { label: this.pageTitle },
        ]
      } else {
        return [
          {
            label: this.$t('トップページ'),
            to: { name: 'Index', params: { portalId: this.portalId } },
          },
          { label: this.pageTitle },
        ]
      }
    },
    isShowBreadcrumb() {
      return this.$route.name !== 'Index'
    },
  },
  watch: {
    '$i18n.locale'() {
      this.$nextTick(() => {
        this.setHeaderHeight()
      })
    },
    portalId() {
      this.$nextTick(() => {
        this.setHeaderHeight()
      })
    },
  },
  mounted() {
    this.setHeaderHeight()
    this.togglePagetopClass()
    window.addEventListener('resize', this.handleWindowResize)
    window.addEventListener('scroll', this.handleWindowScroll)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleWindowResize)
    window.removeEventListener('scroll', this.handleWindowScroll)
  },
  methods: {
    handleSetPageTitle(title) {
      this.pageTitle = title
    },
    handleSetPageParents(pageParents) {
      this.pageParents = pageParents
    },
    handleWindowScroll() {
      this.togglePagetopClass()
    },
    handleWindowResize() {
      this.setHeaderHeight()
    },
    setHeaderHeight() {
      this.headerHeight = this.$refs.header.clientHeight
    },
    togglePagetopClass() {
      const windowHeight = document.documentElement.clientHeight
      const pageHeight = document.documentElement.scrollHeight
      const scrollTop = document.documentElement.scrollTop || window.scrollY
      const footerHeight = this.$refs.footer.clientHeight
      const showLine = Math.floor((windowHeight * 3) / 5)

      this.pagetopStatus.isShow = scrollTop > showLine
      this.pagetopStatus.isFit =
        pageHeight - (scrollTop + windowHeight) <= footerHeight
    },
    toPagetop(e) {
      e.preventDefault()

      gsap.killTweensOf(window)
      gsap.to(window, 1, {
        scrollTo: 0,
        ease: 'power4.out',
      })
    },
    scrollTo(el) {
      const scrollTo = el
        ? {
            y:
              window.pageYOffset +
              el.getBoundingClientRect().top -
              this.$refs.header.clientHeight,
          }
        : { y: 0 }

      gsap.killTweensOf(window)
      gsap.to(window, 1, {
        scrollTo,
        ease: 'power4.inOut',
        onComplete() {
          if (el) el.focus({ preventScroll: true })
        },
      })
    },
  },
}
</script>
