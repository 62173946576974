<template>
  <nav
    class="c-breadcrumb"
    :aria-label="$t('パンくずリスト')"
    v-if="items.length"
  >
    <div class="c-breadcrumb__inner">
      <ol
        class="c-breadcrumb__list"
        vocab="https://schema.org/"
        typeof="BreadcrumbList"
      >
        <li
          v-for="(item, index) in items"
          :key="item.label"
          class="c-breadcrumb__item"
          :aria-current="items.length - 1 !== index ? null : 'page'"
          property="itemListElement"
          typeof="ListItem"
        >
          <app-link
            v-if="items.length - 1 !== index"
            class="c-link"
            :to="item.to"
            :disabled="item.to ? false : true"
            property="item"
            typeof="WebPage"
          >
            <span property="name" v-text="item.label" />
          </app-link>
          <span v-else property="name" v-text="item.label" />
          <meta property="position" :content="index + 1" />
        </li>
      </ol>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'CBreadcrumb',
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
}
</script>
