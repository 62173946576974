<template>
  <app-link
    class="p-footerServiceBanner"
    :class="[
      `p-footerServiceBanner--${servicePortalId}`,
      `p-footerServiceBanner--${this.$i18n.locale || 'ja'}`,
    ]"
    :to="{ name: 'Index', params: { portalId: servicePortalId } }"
  >
    <img
      class="p-footerServiceBanner__logo"
      :src="image.url"
      :width="image.width"
      :height="image.height"
      :alt="altText"
    />
  </app-link>
</template>

<script>
import { LogoImageMap } from '@/components/common/PFooterSiteLogo.vue'

export default {
  name: 'PFooterServiceBanner',
  props: {
    servicePortalId: {
      type: String,
      default: 'shops',
    },
  },
  computed: {
    altText() {
      return this.$t(`${this.servicePortalId}.おみせのトラスト`)
    },
    image() {
      const imageOption =
        LogoImageMap[this.servicePortalId] || LogoImageMap.shops

      return imageOption[this.$i18n.locale] || imageOption.ja
    },
  },
}
</script>
